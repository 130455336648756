import { GoogleLogin } from "@react-oauth/google";
import { useEffect, useState } from "react";
import {
  VuiFlexContainer,
  VuiFlexItem,
  VuiModal,
  VuiSpacer,
  VuiSpinner,
  VuiText,
  VuiTextColor,
  VuiTitle,
} from "../../ui";
import { SearchControls } from "./controls/SearchControls";
import { ExampleQuestions } from "./controls/ExampleQuestions";
import { useSearchContext } from "../../contexts/SearchContext";
import { AppHeader } from "./chrome/AppHeader";
import { AppFooter } from "./chrome/AppFooter";
import { useConfigContext } from "../../contexts/ConfigurationContext";
import { SearchErrorCallout } from "./results/SearchErrorCallout";
import { useAuthenticationContext } from "../../contexts/AuthenticationContext";
import "./searchView.scss";
import { SummaryUx } from "./SummaryUx";

export const SearchView = () => {
  const { isConfigLoaded, app } = useConfigContext();
  const { logIn } = useAuthenticationContext();
  const { onSearch } = useSearchContext();
  const [isReauthModalOpen, setIsReauthModalOpen] = useState(false);

  const {
    isSearching,
    searchError,
    searchResults,
    isSummarizing,
    summarizationError,
    summarizationResponse,
  } = useSearchContext();

  let content;

  if (!isConfigLoaded) {
    content = (
      <VuiFlexContainer
        className="appSpinner"
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <VuiSpinner size="l" />
        <VuiSpacer size="l" />
        <VuiTitle size="xs">
          <h2>Loading</h2>
        </VuiTitle>
      </VuiFlexContainer>
    );
  } else if (
    !isSearching &&
    !searchError &&
    !searchResults &&
    !isSummarizing &&
    !summarizationError &&
    !summarizationResponse
  ) {
    content = <ExampleQuestions />;
  } else if (isSearching) {
    content = (
      <VuiFlexContainer alignItems="center" spacing="m">
        <VuiFlexItem>
          <VuiSpinner size="s" />
        </VuiFlexItem>

        <VuiFlexItem grow={false}>
          <VuiTitle size="s" align="center">
            <h2>Searching</h2>
          </VuiTitle>
        </VuiFlexItem>
      </VuiFlexContainer>
    );
  } else if (searchError) {
    content = <SearchErrorCallout searchError={searchError} />;
  } else {
    content = <SummaryUx />;
  }

  useEffect(() => {
    if (searchError) {
      const isAuthError = searchError?.response?.status === 401;
      if (isAuthError) {
        setIsReauthModalOpen(true);
      }
    }
  }, [searchError]);

  return (
    <>
      {app.isHeaderEnabled && <AppHeader />}
      <VuiFlexContainer
        className="searchView"
        direction="column"
        alignItems="center"
        spacing="none"
      >
        {isConfigLoaded && (
          <VuiFlexItem className="searchControlsContainer">
            <SearchControls hasQuery={Boolean(isSearching || searchResults)} />
          </VuiFlexItem>
        )}

        <VuiFlexItem grow={1} className="searchContent" alignItems="start">
          <VuiSpacer size="m" />
          {content}
        </VuiFlexItem>

        {app.isFooterEnabled && <AppFooter />}
      </VuiFlexContainer>

      <VuiModal
        color="primary"
        title={
          <VuiTitle size="s">
            <h2>Please log in again</h2>
          </VuiTitle>
        }
        isOpen={isReauthModalOpen}
        onClose={() => setIsReauthModalOpen(false)}
      >
        <VuiFlexContainer
          direction="column"
          alignItems="center"
          justifyContent="center"
          spacing="l"
        >
          <VuiFlexItem>
            <VuiText align="center">
              <VuiTextColor color="subdued">
                Your session has expired. Log in again.
              </VuiTextColor>
            </VuiText>
          </VuiFlexItem>

          <VuiFlexItem>
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                const user = logIn(credentialResponse.credential);
                setIsReauthModalOpen(false);
                onSearch({ userOverride: user });
              }}
              onError={() => {
                console.error("Login Failed");
              }}
            />
          </VuiFlexItem>
        </VuiFlexContainer>
      </VuiModal>
    </>
  );
};
