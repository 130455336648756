import { VuiSpacer, VuiTitle, VuiHorizontalRule, VuiSummary } from "../../ui";
import { sanitizeCitations } from "../../ui/utils/citations";
import { useSearchContext } from "../../contexts/SearchContext";
import { SearchResultList } from "./results/SearchResultList";
import { SummaryCitation } from "./summary/SummaryCitation";
import { ConfidenceScore } from "./summary/ConfidenceScore";

export const SummaryUx = () => {
  const {
    searchResults,
    summarizationResponse,
    searchResultsRef,
    selectedSearchResultPosition,
    summaryEnableHem,
  } = useSearchContext();

  const rawSummary = summarizationResponse;
  const summary = sanitizeCitations(rawSummary);

  return (
    <>
      <VuiSpacer size="l" />

      <VuiTitle size="xs">
        <h2 style={{ display: "flex", alignItems: "center" }}>
          <strong>Summary</strong>
        </h2>
      </VuiTitle>

      <VuiSpacer size="s" />
      {
          summary && (
              <VuiSummary
                  summary={summary}
                  SummaryCitation={SummaryCitation}
              />
          )
      }

      <VuiSpacer size="s" />

      {summaryEnableHem && (
        <ConfidenceScore
          rawSummary={rawSummary}
          summarySearchResults={searchResults ?? []}
        />
      )}

      <VuiSpacer size="l" />
      <VuiHorizontalRule />
      <VuiSpacer size="l" />

      <VuiTitle size="xs">
        <h2>
          <strong>References</strong>
        </h2>
      </VuiTitle>

      <VuiSpacer size="s" />

      <SearchResultList
        results={searchResults ?? []}
        selectedSearchResultPosition={selectedSearchResultPosition}
        setSearchResultRef={(el: HTMLDivElement | null, index: number) =>
          (searchResultsRef.current[index] = el)
        }
      />
    </>
  );
};
