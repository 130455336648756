import {
  VuiFlexContainer,
  VuiFlexItem,
  VuiTitle,
  VuiText,
  VuiSpacer,
} from "../../../ui";
import "./appFooter.scss";

export const AppFooter = () => {
  return (
    <div className="appFooter">
      <div className="appFooterContent">
        <VuiFlexContainer
          alignItems="start"
          spacing="l"
          className="appFooterContent__layout"
        >
          <VuiFlexItem grow={5}>
            <VuiTitle size="s">
              <h3>Git commit</h3>
            </VuiTitle>

            <VuiSpacer size="m" />

            <VuiText>
              <p>
                <a
                  href={`https://github.com/vectara/knowledge-base/commits/main/#:~:text=${process.env.REACT_APP_GIT_SHA}`}
                  target="_blank"
                >
                  {process.env.REACT_APP_GIT_SHA}
                </a>
              </p>
            </VuiText>
          </VuiFlexItem>
        </VuiFlexContainer>
      </div>
    </div>
  );
};
