import { forwardRef } from "react";
import {
  VuiText,
  VuiTextColor,
  VuiFlexContainer,
  VuiFlexItem,
  VuiSearchResult,
} from "../../../ui";
import { truncateEnd, truncateStart } from "../../../ui/utils/truncateString";
import { useConfigContext } from "../../../contexts/ConfigurationContext";
import { DeserializedSearchResult } from "../types";
import "./SearchResult.scss";
import { Icon } from '@iconify-icon/react';

type Props = {
  result: DeserializedSearchResult;
  position: number;
  isSelected: boolean;
};

const sourceLogoLable: Record<string, string> = {
  gdrive: "logos:google-drive",
  jira: "logos:jira",
  notion: "logos:notion-icon",
  github: "logos:github-icon",
  slack: "logos:slack-icon",
  docusaurus: "simple-line-icons:docs",
  discourse: "material-symbols:forum",
  website: "icon-park:web-page"
}

const CONTEXT_MAX_LENGTH = 200;

export const SearchResult = forwardRef<HTMLDivElement | null, Props>(
  ({ result, position, isSelected }: Props, ref) => {
    const { filters } = useConfigContext();

    const {
      source,
      title,
      url,
      snippet: { pre, post, text },
    } = result;

    return (
      <VuiSearchResult
        ref={ref}
        isSelected={isSelected}
        result={{
          title,
          url,
          snippet: {
            pre: truncateStart(pre, CONTEXT_MAX_LENGTH),
            text,
            post: truncateEnd(post, CONTEXT_MAX_LENGTH),
          },
        }}
        position={position + 1}
        subTitle={
          Boolean(filters.isEnabled || url) && (
            <VuiFlexContainer
              alignItems="center"
              spacing="xs"
              className="searchResultFilterGroup"
            >
              {filters.isEnabled && (
                <VuiFlexItem>
                  <Icon icon={sourceLogoLable[source]} />
                </VuiFlexItem>
              )}

              {url && (
                <VuiFlexItem grow={1}>
                  <VuiText size="s" className="searchResultSiteCategory">
                    <p>
                      <VuiTextColor color="subdued">{url}</VuiTextColor>
                    </p>
                  </VuiText>
                </VuiFlexItem>
              )}
            </VuiFlexContainer>
          )
        }
      />
    );
  }
);
